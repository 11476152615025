import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const hamburger = document.querySelector('.hamburger');
const hamburgerLines = document.querySelectorAll('.hamburger__line');
const header = document.querySelector('header');
const nav = document.querySelector('nav');

nav?.addEventListener('click', closeNavBar);
hamburger?.addEventListener('click', hamburgerClickHandler);
window.addEventListener('resize', windowResizeHandler);

function preventDefault(e: Event) {
	e.preventDefault();
}

const scrollingKeysMap: { [key: string]: true } = {
	ArrowDown: true,
	Down: true,
	ArrowLeft: true,
	Left: true,
	ArrowRight: true,
	Right: true,
	ArrowUp: true,
	Up: true,
	" ": true,
	Spacebar: true,
	PageUp: true,
	PageDown: true,
	End: true,
	Home: true,
};


function preventDefaultForScrollKeys(e: KeyboardEvent) {
	if (e.key in scrollingKeysMap) {
		preventDefault(e);
		return false;
	}
}

// modern Chrome requires { passive: false } when adding event
let supportsPassive = false;
try {
	(window.addEventListener as any)(
		'test',
		null,
		Object.defineProperty({}, 'passive', {
			get: function () {
				supportsPassive = true;
			},
		})
	);
} catch (e) { }

const wheelOpt = supportsPassive ? { passive: false } : false;
const wheelEvent =
	'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

// call this to Disable
function disableScroll() {
	window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
	window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
	window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
	window.addEventListener('keydown', preventDefaultForScrollKeys, false);
	const body = document.querySelector('body');
	if (body) {
		body.style.overflow = 'hidden';
	}
}

// call this to Enable
function enableScroll() {
	window.removeEventListener('DOMMouseScroll', preventDefault, false);
	window.removeEventListener(wheelEvent, preventDefault, wheelOpt as any);
	window.removeEventListener('touchmove', preventDefault, wheelOpt as any);
	window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
	const body = document.querySelector('body');
	if (body) {
		body.style.overflow = 'visible';
	}
}

let menuOpen = false;
function hamburgerClickHandler() {
	if (header) header.classList.toggle('move-in');
	hamburgerLines.forEach((el) =>
		el.classList.toggle('hamburger-transformation')
	);
	hamburgerLines[0].classList.toggle('hamburger-transformation--top');
	hamburgerLines[1].classList.toggle('hamburger-transformation--middle');
	hamburgerLines[2].classList.toggle('hamburger-transformation--bottom');
	if (!menuOpen) {
		disableScroll();
		menuOpen = true;
	} else {
		enableScroll();
		menuOpen = false;
	}
}

function closeNavBar() {
	if (header) header.classList.remove('move-in');
	hamburgerLines.forEach((el) =>
		el.classList.remove('hamburger-transformation')
	);
	hamburgerLines[0].classList.remove('hamburger-transformation--top');
	hamburgerLines[1].classList.remove('hamburger-transformation--middle');
	hamburgerLines[2].classList.remove('hamburger-transformation--bottom');
	enableScroll();
}

function windowResizeHandler() {
	if (window.innerWidth > 850) {
		closeNavBar();
	}
}

// slide-in animations
const slideIns = document.querySelectorAll('.slide-in');
slideIns.forEach((el) => {
	gsap.from(el, {
		scrollTrigger: {
			trigger: el,
			toggleActions: 'play none none none',
		},
		ease: 'power.inOut',
		yPercent: 25,
		opacity: 0,
	});
});